import about from './about';
import activity from './activity';
import analyticsConsent from './analytics-consent';
import attribution from './attribution';
import authReturn from './auth-return';
import avatar from './avatar';
import avatarList from './avatar-list';
import channel from './channel';
import channelBookmark from './channel-bookmark';
import channelDocumentItems from './channel-document-items';
import channelInvitationConfirm from './channel-invitation-confirm';
import channelInvitationLink from './channel-invitation-link';
import channelInvitationLinkConfirm from './channel-invitation-link-confirm';
import channelInvitationNew from './channel-invitation-new';
import channelInvitations from './channel-invitations';
import channelMemberUpdate from './channel-member-update';
import channelMembers from './channel-members';
import channelNew from './channel-new';
import channelSelector from './channel-selector';
import channelSettings from './channel-settings';
import channelShare from './channel-share';
import channels from './channels';
import channelsInvitations from './channels-invitations';
import channelsList from './channels-list';
import comment from './comment';
import confirmModal from './confirm-modal';
import contact from './contact';
import discussionList from './discussion-list';
import discussionShareIcon from './discussion-share-icon';
import discussionThreadView from './discussion-thread-view';
import documentInfoKudos from './document-info-kudos';
import documentItem from './document-item';
import documentItemMetadataModal from './document-item-metadata-modal';
import documentItemText from './document-item-text';
import documentItemsDropdown from './document-items-dropdown';
import documentNew from './document-new';
import documentSidenav from './document-sidenav';
import documentSidenavOutlineItem from './document-sidenav-outline-item';
import documentSidenavPagination from './document-sidenav-pagination';
import documentSidenavSearch from './document-sidenav-search';
import documentSubscriptionButton from './document-subscription-button';
import documentUploadModal from './document-upload-modal';
import dropdownSelect from './dropdown-select';
import extensionButtons from './extension-buttons';
import feedback from './feedback';
import feedbackButton from './feedback-button';
import fileSelectButton from './file-select-button';
import filterButton from './filter-button';
import helpMarkdown from './help-markdown';
import hivers from './hivers';
import inlineEditable from './inline-editable';
import jobs from './jobs';
import legalNotice from './legal-notice';
import login from './login';
import mainPage from './main-page';
import margin from './margin';
import marginCluster from './margin-cluster';
import marginClusterPane from './margin-cluster-pane';
import marginClusterPlaceholder from './margin-cluster-placeholder';
import marginDiscussion from './margin-discussion';
import marginDiscussionEdit from './margin-discussion-edit';
import marginDraftPane from './margin-draft-pane';
import marginLink from './margin-link';
import marginReply from './margin-reply';
import marginReplyEdit from './margin-reply-edit';
import navbar from './navbar';
import navbarSearch from './navbar-search';
import navbarUser from './navbar-user';
import newReply from './new-reply';
import notFound from './not-found';
import notifications from './notifications';
import officeMap from './office-map';
import onboarding from './onboarding';
import onboardingBookmark from './onboarding-bookmark';
import onboardingChannel from './onboarding-channel';
import onboardingProfile from './onboarding-profile';
import partners from './partners';
import passwordRequest from './password-request';
import passwordReset from './password-reset';
import pdf from './pdf';
import pdfHighlight from './pdf-highlight';
import pdfPopup from './pdf-popup';
import pdfSelectionPopup from './pdf-selection-popup';
import personDocumentSubscriptions from './person-document-subscriptions';
import phFooter from './ph-footer';
import privacy from './privacy';
import publishers from './publishers';
import search from './search';
import searchChips from './search-chips';
import searchDate from './search-date';
import searchDateDropdown from './search-date-dropdown';
import searchDonut from './search-donut';
import searchDropdown from './search-dropdown';
import settings from './settings';
import settingsAccounts from './settings-accounts';
import settingsEmail from './settings-email';
import settingsNotifications from './settings-notifications';
import settingsProfile from './settings-profile';
import signup from './signup';
import subscribe from './subscribe';
import subscribed from './subscribed';
import terms from './terms';
import urlShare from './url-share';
import user from './user';
import userProfile from './user-profile';

export default function(app) {
  about(app);
  activity(app);
  analyticsConsent(app);
  attribution(app);
  authReturn(app);
  avatar(app);
  avatarList(app);
  channel(app);
  channelBookmark(app);
  channelDocumentItems(app);
  channelInvitationConfirm(app);
  channelInvitationLink(app);
  channelInvitationLinkConfirm(app);
  channelInvitationNew(app);
  channelInvitations(app);
  channelMembers(app);
  channelMemberUpdate(app);
  channelSelector(app);
  channelSettings(app);
  channels(app);
  channelShare(app);
  channelsInvitations(app);
  channelsList(app);
  channelNew(app);
  comment(app);
  confirmModal(app);
  contact(app);
  discussionList(app);
  discussionShareIcon(app);
  discussionThreadView(app);
  documentInfoKudos(app);
  documentItem(app);
  documentItemsDropdown(app);
  documentItemMetadataModal(app);
  documentItemText(app);
  documentNew(app);
  documentSidenav(app);
  documentSidenavOutlineItem(app);
  documentSidenavPagination(app);
  documentSidenavSearch(app);
  documentSubscriptionButton(app);
  documentUploadModal(app);
  dropdownSelect(app);
  extensionButtons(app);
  feedback(app);
  feedbackButton(app);
  fileSelectButton(app);
  filterButton(app);
  helpMarkdown(app);
  hivers(app);
  jobs(app);
  inlineEditable(app);
  legalNotice(app);
  login(app);
  mainPage(app);
  margin(app);
  marginCluster(app);
  marginClusterPane(app);
  marginClusterPlaceholder(app);
  marginDiscussion(app);
  marginDiscussionEdit(app);
  marginDraftPane(app);
  marginLink(app);
  marginReply(app);
  marginReplyEdit(app);
  navbar(app);
  navbarSearch(app);
  navbarUser(app);
  newReply(app);
  notFound(app);
  notifications(app);
  officeMap(app);
  onboarding(app);
  onboardingBookmark(app);
  onboardingChannel(app);
  onboardingProfile(app);
  partners(app);
  passwordRequest(app);
  passwordReset(app);
  pdf(app);
  pdfHighlight(app);
  pdfPopup(app);
  pdfSelectionPopup(app);
  personDocumentSubscriptions(app);
  phFooter(app);
  privacy(app);
  publishers(app);
  search(app);
  searchChips(app);
  searchDate(app);
  searchDateDropdown(app);
  searchDonut(app);
  searchDropdown(app);
  settings(app);
  settingsAccounts(app);
  settingsEmail(app);
  settingsNotifications(app);
  settingsProfile(app);
  signup(app);
  subscribe(app);
  subscribed(app);
  terms(app);
  urlShare(app);
  user(app);
  userProfile(app);
}
