import affix from './affix';
import attributes from './attributes';
import confirmedClick from './confirmed-click';
import elastic from './elastic';
import kramjax from './kramjax';
import mathjax from './mathjax';
import onOutside from './on-outside';
import onRendered from './on-rendered';
import onResized from './on-resized';
import onVisibilityChanged from './on-visibility-changed';
import pdf from './pdf';
import scroll from './scroll';
import subnav from './subnav';
import toc from './toc';
import validateUsername from './validate-username';
import viewportHeight from './viewport-height';

export default function(app) {
  affix(app);
  attributes(app);
  confirmedClick(app);
  elastic(app);
  kramjax(app);
  mathjax(app);
  onOutside(app);
  onRendered(app);
  onResized(app);
  onVisibilityChanged(app);
  pdf(app);
  scroll(app);
  subnav(app);
  toc(app);
  validateUsername(app);
  viewportHeight(app);
};
